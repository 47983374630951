<template>
  <b-modal
    id="employee-daily-leave-modal"
    title="Employee Leave Details"
    ok-only
    :busy="isBusy"
  >
    <CommonEmployeeSummary
      :item="employee"
      class="mb-10"
    ></CommonEmployeeSummary>
    <div v-if="!isBusy">
      <EmployeeDailyLeave :leave="selectedLeave"></EmployeeDailyLeave>
    </div>
    <div v-else>
      <AppLoader></AppLoader>
    </div>
  </b-modal>
</template>

<script>
import EmployeeDailyLeave from "@/modules/company/components/attendance/daily/EmployeeDailyLeave";
import CommonEmployeeSummary from "@/modules/core/components/company/CommonEmployeeSummary";
import { mapGetters } from "vuex";
import AppLoader from "@/modules/core/components/loaders/AppLoader";
export default {
  name: "EmployeeDailyLeaveModal",
  components: { AppLoader, CommonEmployeeSummary, EmployeeDailyLeave },
  props: {
    selectedLeave: {
      type: Object,
      required: true,
    },
    employee: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      isBusy: "getDailyLeaveAttendanceIsBusy",
    }),
  },
};
</script>

<style scoped></style>
