<template>
  <div>
    <EmployeeDailyAttendanceTable
      :items="items"
      :is-busy="isBusyStore"
      :pagination="pagination"
      @expandDetails="showClockRecord"
      @showManageModal="showManageModal"
      @changePage="handlePageChange"
      @showUpdateModal="showUpdateModal"
    ></EmployeeDailyAttendanceTable>

    <EmployeePageClockRecordModal
      v-bind:attendance-id="attendanceId"
    ></EmployeePageClockRecordModal>

    <EmployeeAttendanceManageModal
      @formSubmitted="fetch"
    ></EmployeeAttendanceManageModal>

    <EmployeeAttendanceUpdateTimeModal
      :employee="employee"
      :attendance="attendance"
      @formSubmitted="fetch"
    ></EmployeeAttendanceUpdateTimeModal>

    <EmployeeDailyLeaveModal
      :selected-leave="selectedLeave"
      :employee="employee"
    ></EmployeeDailyLeaveModal>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import EmployeePageClockRecordModal from "@/modules/company/components/employees/EmployeePageClockRecordModal";
import EmployeeAttendanceManageModal from "@/modules/company/components/attendance/manage/EmployeeAttendanceManageModal";
import EmployeeDailyAttendanceTable from "@/modules/company/components/shared/attendance/daily/EmployeeDailyAttendanceTable";
import EmployeeAttendanceUpdateTimeModal from "@/modules/company/components/attendance/manage/time/EmployeeAttendanceUpdateTimeModal";
import EmployeeDailyLeaveModal from "@/modules/company/components/attendance/daily/EmployeeDailyLeaveModal";

export default {
  name: "EmployeeDailyAttendance",
  components: {
    EmployeeDailyLeaveModal,
    EmployeeAttendanceUpdateTimeModal,
    EmployeeDailyAttendanceTable,
    EmployeeAttendanceManageModal,
    EmployeePageClockRecordModal,
  },
  data() {
    return {
      attendanceId: null,
      employee: {},
      attendance: {},
      selectedLeave: {},
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.$store.dispatch("fetchEmployeeDailyAttendance");
    },
    /**
     * Show clock record.
     *
     * @param data
     * @returns {null}
     */
    showClockRecord(data) {
      console.log(data);
      if (data.item.att_status?.id === 3) {
        console.log("Opening Leave Modal.");
        this.showDailyLeave(data.item);
      } else {
        if (data.item.attendance === null) {
          this.$bvModal.msgBoxOk(
            "Employee has to check in first for their clock records to appear",
            {
              title: "Attendance is empty",
              centered: true,
            }
          );
          return null;
        }
        this.attendanceId = data.item.attendance
          ? data.item.attendance.id
          : null;
        this.$bvModal.show("employee-page-clock-record-modal");
      }
    },

    /**
     *
     *
     * @param row
     */
    async showDailyLeave(row) {
      console.log("Show daily leave", row);
      this.employee = {
        email: row.email,
        name: row.name,
        code: row.code,
        image_url: row.image_url,
      };

      try {
        this.$bvModal.show("employee-daily-leave-modal");
        const res = await this.$store.dispatch("fetchDailyLeaveAttendance", {
          id: row.id,
        });

        console.log(res.data.data);
        this.selectedLeave = res.data.data;
      } catch (e) {
        console.error(e);
        await this.$bvModal.msgBoxOk("Failed to fetch employee leave details.");
      }
    },

    /**
     * Show modal.
     *
     * @param data
     */
    showManageModal(data) {
      console.log(data);
      this.$store.commit("setDailyEmployeeAttendanceFormState", false);
      this.$store.dispatch("assignDailyEmployeeAttendance", {
        data: data.item,
        date: dayjs(new Date()).format(),
      });
      this.$bvModal.show("emp-attendance-manage-modal");
    },

    /**
     * Show start/end time update modal.
     *
     * @param data
     * @returns {null}
     */
    showUpdateModal(data) {
      this.employee = data.item;
      console.log("Employee", this.employee);

      if (data.item.attendance === null) {
        this.$bvModal.msgBoxOk("Employee doesn't has attendance", {
          title: "Attendance is empty",
          centered: true,
        });
        return null;
      }

      this.attendance = data.item.attendance;
      console.log("Attendance", this.attendance);

      this.$bvModal.show("common-attendance-update-time-modal");
    },

    /**
     *
     * @param page
     */
    handlePageChange(page) {
      this.$store.commit("setEmployeeDailyAttendancePage", page);
      this.fetch();
    },
  },
  computed: {
    ...mapGetters({
      items: "getEmployeeDailyAttendanceList",
      pagination: "getEmployeeDailyAttendancePagination",
      isBusyStore: "getEmployeeDailyAttendanceBusy",
    }),
  },
};
</script>

<style scoped></style>
